
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.network {
  display: flex;
  flex-direction: row;
  @include for-size(phone-portrait-down) {
    flex-direction: column;
  }
  &__label {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
  }
  &__type {
    width: 200px;
  }
  &__inputs {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    @include for-size(phone-portrait-down) {
      margin-top: 10px;
    }
  }
  &__select {
    width: 100%;
    max-width: 240px;
    margin-left: 10px;
  }
  .input--invalid &__select {
    border-color: #ff9494;
  }
  &__input {
    max-width: 180px;
  }
  &__separator {
    margin: 0 10px;
  }
}
